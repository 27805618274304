<template>
  <b-col cols="6">
    <div class="mb-1 d-flex align-items-center justify-content-between" >
      <b-dropdown
        :text="textType"
        variant="primary"
      >
        <b-dropdown-item
          @click="setType('products')"
        >
          {{ $t('Productos') }}
        </b-dropdown-item>
        <b-dropdown-item
          @click="setType('assets')"
        >
          {{ $t('Activos') }}
        </b-dropdown-item>
        <b-dropdown-item
          @click="setType('sets')"
        >
          {{ $t('Agrupaciones') }}
        </b-dropdown-item>
      </b-dropdown>
      <div v-if="type != 'sets'" >
        <small class="mr-1" >{{ $t('Filtro') }}</small>
        <b-dropdown
          :text="textFilterCat"
          variant="outline-secondary"
        >
          <b-dropdown-item
              @click="setGroup('')"
          >
            {{ $t('Todos') }}
          </b-dropdown-item>
          <b-dropdown-item
              v-for="(opt, index) in selectGroups"
              :key="index"
              @click="setGroup(opt)"
          >
            {{ opt.description }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <b-table-simple hover small caption-top responsive>
      <b-thead>
        <b-tr>
          <b-th>
            {{ $t('Nombre') }}
          </b-th>
        </b-tr>
      </b-thead>
      <draggable :list="elements" :group="{name:'products',  pull:'clone', put:false }" tag="tbody" >
        <b-tr :key="index" v-for="(item, index) in elements">
          <b-td class="d-flex align-items-center justify-content-between py-2" >
            <div>
              <div
                  v-if="type === 'products'"
                  :style="`background-image: url('${item.imagen}');`"
                  class="d-inline-block img-product-list mr-50"
              >
              </div>
              <div
                  v-if="type === 'assets'"
                  :style="`background-image: url('${item.product.imagen}');`"
                  class="d-inline-block img-product-list mr-50"
              >
              </div>
              <p
                  class="d-inline-block m-0 font-weight-bolder"
              >
                <span class="d-block" >{{ item.name }}</span>
                <b-badge
                  v-if="type === 'sets'"
                  pill
                  variant="primary"
                  class="badge-sm"
                >PACK</b-badge>
                <b-badge
                  v-if="type === 'products'"
                  pill
                  variant="success"
                  class="badge-sm"
                >PRODUCTO</b-badge>
                <b-badge
                  v-if="type === 'assets'"
                  pill
                  variant="danger"
                  class="badge-sm"
                >ACTIVO</b-badge>
              </p>
            </div>
            <feather-icon
              @click="changeOpenPack(index)"
              icon="ChevronDownIcon"
              size="20"
              v-if="type === 'sets'"
            />
          </b-td>
          <draggable
            :list="item.objects"
            :group="{name:'products',  pull:'clone', put:false }"
            tag="tbody"
            v-if="type === 'sets' && openPack == index"
          >
            <b-tr :key="index1" v-for="(item1, index1) in item.objects">
              <b-td class="d-flex align-items-center px-3" >
                <div :style="`background-image: url('${item1.imagen}');`" class="d-inline-block img-product-list mr-50" >
                </div>
                <div>
                  <p class="m-0" >{{ item1.name }}</p>
                  <small v-if="item1.type === 'product'" >{{ $t('Producto') }}</small>
                  <small v-if="item1.type === 'asset'" >{{ $t('Activo') }}</small>
                </div>
              </b-td>
            </b-tr>
          </draggable>
        </b-tr>
      </draggable>
    </b-table-simple>
    <div class="d-flex align-items-center justify-content-between mb-2">
      <div class="d-flex align-items-center mb-0">
        <span class="text-nowrap ">
          {{ $t('PorPagina') }}:
        </span>
        <b-form-select
          v-model="pageLength"
          :options="pages"
          class="ml-50 mr-1"
          @input="handlePageChange"
        />
        <span class="text-nowrap"> {{ $t('Total') }}: {{ totalElements }}</span>
      </div>
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalElements"
          :per-page="pageLength"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
  </b-col>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import {
  BCol,
  BTh,
  BTr,
  BTd,
  BTableSimple,
  BThead,
  BPagination,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BTh,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    draggable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BBadge,
  },
  data() {
    return {
      type: 'products',
      openPack: 9999,
      searchTerm: '',
      group: '',
      currentPage: 1,
      pageLength: 10,
      pages: ['10', '15', '25'],
    }
  },
  computed: {
    ...mapGetters({
      currentCompany: 'companies/getCurrentCompany',
      currentClient: 'clients/getCurrentClient',
      products: 'products/getItems',
      assets: 'assets/getItems',
      sets: 'sets/getItems',
      totalProducts: 'products/getTotalItems',
      totalAssets: 'assets/getTotalItems',
      totalSets: 'sets/getTotalItems',
      selectGroups: 'groups/getSelectGroups',
    }),
    elements() {
      let output = []
      if (this.type === 'products') {
        this.products.forEach(element => {
          element.type = 'product'
          output.push(element)
        })
      } else if (this.type === 'assets') {
        this.assets.forEach(element => {
          element.type = 'asset'
          output.push(element)
        })
      } else if (this.type === 'sets') {
        this.sets.forEach(element => {
          element.type = 'set'
          const arrayObjects = []
          element.objects.forEach(obj => {
            obj.object.type = obj.type
            arrayObjects.push(obj.object)
          })
          element.objects = arrayObjects
          output.push(element)
        })
      }
      return output
    },
    totalElements() {
      if (this.type === 'products') {
        return this.totalProducts
      } else if (this.type === 'assets') {
        return this.totalAssets
      } else if (this.type === 'sets') {
        return this.totalSets
      }
      return 0
    },
    textFilterCat() {
      if (this.group) {
        return this.group.description
      }
      return this.$t('Todos')
    },
    textType() {
      if (this.type === 'products') {
        return this.$t('Productos')
      } else if (this.type === 'assets') {
        return this.$t('Activos')
      } else if (this.type === 'sets') {
        return this.$t('Agrupaciones')
      }
      return ''
    },
  },
  watch: {
    elements() {
      this.openPack = 999
    },
    group() {
      this.chargeElements()
    },
    type() {
      this.chargeElements()
    },
  },
  methods: {
    ...mapActions({
      getProducts: 'products/getListProducts',
      getAssets: 'assets/getListAssets',
      getSets: 'sets/getListSets',
      getSelectGroups: 'groups/getSelectGroups',
    }),
    chargeElements() {
      let group = ''
      this.openPack = 999
      if (this.group) {
        group = this.group.id
      }
      if (this.type === 'products') {
        this.getProducts({
          company: this.currentCompany.id, page: this.currentPage, per_page: this.pageLength, search: this.searchTerm, group,
        })
      } else if (this.type === 'assets') {
        this.getAssets({
          client: this.currentClient.id, page: this.currentPage, per_page: this.pageLength, search: this.searchTerm, group,
        })
      } else if (this.type === 'sets') {
        this.getSets({
          client: this.currentClient.id, page: this.currentPage, per_page: this.pageLength, search: this.searchTerm,
        })
      }
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeElements()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeElements()
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeElements()
    },
    setGroup(groupSelect) {
      this.group = groupSelect
    },
    setType(type) {
      this.type = type
    },
    changeOpenPack(index) {
      if (this.openPack === index) {
        this.openPack = 999
      } else {
        this.openPack = index
      }
    },
  },
  async created() {
    await this.chargeElements()
    await this.getSelectGroups({ family_id: '' })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
